import React, { FC, useRef, useState } from "react";
import parse from "html-react-parser";
import { injectIntl } from "gatsby-plugin-intl";
import { Link } from "gatsby";
import AnalysisVideo from "../../../images/analysis.webm";
// @ts-ignore

const ServiceTakeAnalysis: FC<ServiceTakeAnalysisProps> = ({ intl }) => {
  const videoRef = useRef(null);
  const handleMouseOut = () => {
    // @ts-ignore
    videoRef.current.pause();
  };
  const handleMouseOver = () => {
    // @ts-ignore
    videoRef.current.play();
    console.log("play");
  };

  return (
    <div className="flex  lg:justify-center sm:justify-around xs:justify-center items-center bg-[#FFF5EB] xl:w-[37rem] lg:w-[31rem] lg:h-[18.75rem] sm:w-[42rem] sm:h-[16rem] xs:w-[19rem] xs:h-[13.9rem] rounded-[1.25rem]">
      <div className="flex flex-col lg:w-auto sm:w-[55%]  ">
        <h2 className="xs:mb-[1.25rem] font-semibold xl:text-[2rem] sm:text-[1.6rem] xs:text-[1.14rem] leading-8 lg:ml-[2rem] sm:ml-[0rem] xs:ml-[1rem]">
          {" "}
          {parse(
            intl.formatMessage({
              id: "ServiceMenu.ServiceTakeAnalysis.ServiceTakeAnalysisHeader",
            })
          )}
        </h2>
        <div className="lg:w-[65%] sm:w-[100%] xs:w-[75%] lg:ml-[2rem] sm:ml-[0rem] xs:ml-[1rem] ">
          <p className="xs:mb-[1.25rem] font-regular lg:text-[1.125rem] sm:text-[1rem] xs:text-[0.8rem]">
            {parse(
              intl.formatMessage({
                id: "ServiceMenu.ServiceTakeAnalysis.ServiceTakeAnalysisDescription",
              })
            )}
          </p>
          <Link to="/analysis-and-prices">
            <button className="lg:mb-[2rem] sm:mb-[1.25rem] xs:mb-[1rem] grid bg-[#FF922E] hover:bg-[#FA7F2f] text-white justify-center items-center lg:w-[16.625rem] sm:w-[12.5rem] sm:h-[2.5rem] xs:w-[9.5rem] xs:h-[2rem] sm:rounded-[0.56rem] xs:rounded-[0.5rem] font-semibold lg:text-[1.3rem] sm:text-[1rem] xs:text-[0.75rem]">
              {parse(
                intl.formatMessage({
                  id: "ServiceMenu.ServiceTakeAnalysis.ServiceTakeAnalysisButton",
                })
              )}
            </button>
          </Link>
        </div>

        <Link to="/analysis-detail">
          <p className="lg:ml-[2rem] sm:ml-[0rem] xs:ml-[1rem] cursor-pointer text-[#FF922E] font-semibold sm:text-[1.25rem] xs:text-[0.7rem]">
            {parse(
              intl.formatMessage({
                id: "ServiceMenu.ServiceTakeAnalysis.ServiceTakeAnalysisPreparation",
              })
            )}
          </p>
        </Link>
      </div>
      <div className="xl:w-[35%] lg:w-[25%] sm:w-[30%] xs:w-[25%] cursor-pointer">
        <video
          className="lg:w-[10rem] lg:h-[10rem] sm:w-[13rem] sm:h-[13rem] xs:w-[6rem] xs:h-[6rem] mb-10"
          ref={videoRef}
          onMouseOver={handleMouseOver}
          onMouseOut={handleMouseOut}
          muted
        >
          <source
            src="../../../images/analysis_safari.mov"
            type='video/mov; codecs="hvc1"'
          />
          <source src={AnalysisVideo} type="video/webm;" />
        </video>
      </div>
    </div>
  );
};

export default injectIntl(ServiceTakeAnalysis);

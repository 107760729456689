import * as React from "react";
// import "./Location.css";
import { injectIntl } from "gatsby-plugin-intl";
import parse from "html-react-parser";

import Phone from "../../../images/phone_black.svg";
import WPhone from "../../../images/whatsapp_black.svg";
import Stopwatch from "../../../images/stopwatch.svg";
import Message from "../../../images/message.svg";
import Point from "../../../images/map_black.svg";
import { useEffect, useState } from "react";
import useScreenSize from "use-screen-size";
// @ts-ignore
const Location = ({ intl }) => {
  const [sizeOfDisplay, setSizeOfDisplay] = useState(1280);

  const size = useScreenSize();

  useEffect(() => setSizeOfDisplay(size.width), [size.width]);

  return (
    <div className="grid xs:justify-center  sm:pl-0">
      <div className="mt-[5.5rem]  grid justify-start xl:max-w-[1200px] xl:w-[1200px] lg:max-w-[1000px] lg:w-[1000px] sm:max-w-[680px] sm:w-[680px] xs:max-w-[300px] xs:w-[300px] sm:mb-[5rem] xs:mb-[2rem] lg:pl-[0rem] ">
        <div className="grid justify-start ">
          <h2 className=" h-[2rem] lg:text-[2rem] sm:text-[1.75rem] xs:text-[1.25rem] font-semibold leading-5">
            {parse(intl.formatMessage({ id: "Contacts" }))}
          </h2>
        </div>
        {/*grid sm:grid-cols-2 xs:grid-cols-1 */}
        <div className="flex sm:flex-row xs:flex-col items-start sm:mt-[2.5rem] xs:mt-[2rem]">
          <div className="rounded-[1rem] grid justify-left   ">
            {sizeOfDisplay >= 1280 ? (
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2906.7793006777943!2d76.9058599541753!3d43.23508668637848!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3883692f741f76fb%3A0xf50130a1e47dabed!2sZhandosov%20Street%201%2C%20Almaty%20050057!5e0!3m2!1sen!2skz!4v1676436129828!5m2!1sen!2skz"
                width="640"
                height="372"
                style={{ border: 0, borderRadius: 20 }}
                allowFullScreen={false}
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            ) : (
              ""
            )}
            {sizeOfDisplay >= 1024 && sizeOfDisplay < 1280 ? (
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2906.7793006777943!2d76.9058599541753!3d43.23508668637848!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3883692f741f76fb%3A0xf50130a1e47dabed!2sZhandosov%20Street%201%2C%20Almaty%20050057!5e0!3m2!1sen!2skz!4v1676436129828!5m2!1sen!2skz"
                width="540"
                height="313"
                style={{ border: 0, borderRadius: 20 }}
                allowFullScreen={false}
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            ) : (
              ""
            )}
            {sizeOfDisplay >= 767 && sizeOfDisplay < 1024 ? (
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2906.7793006777943!2d76.9058599541753!3d43.23508668637848!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3883692f741f76fb%3A0xf50130a1e47dabed!2sZhandosov%20Street%201%2C%20Almaty%20050057!5e0!3m2!1sen!2skz!4v1676436129828!5m2!1sen!2skz"
                width="407"
                height="236"
                style={{ border: 0, borderRadius: 20 }}
                allowFullScreen={false}
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            ) : (
              ""
            )}
            {sizeOfDisplay < 768 ? (
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2906.7793006777943!2d76.9058599541753!3d43.23508668637848!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3883692f741f76fb%3A0xf50130a1e47dabed!2sZhandosov%20Street%201%2C%20Almaty%20050057!5e0!3m2!1sen!2skz!4v1676436129828!5m2!1sen!2skz"
                width="300"
                height="192"
                style={{ border: 0, borderRadius: 20 }}
                allowFullScreen={false}
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            ) : (
              ""
            )}
          </div>

          <div className="grid grid-rows-6 grid-flow-col gap-11 max-[1000px]:gap-4 sm:ml-[4rem] sm:mt-[0rem] xs:mt-[2rem] ">
            <div className="lg:w-[100%] sm:w-[100%] xs:w-[100%] flex justify-start items-start">
              <img src={Point} alt="" className="mt-[0.2rem]" />
              <p className="lg:text-[1rem] sm:text-[1rem] xs:text-[0.875rem] lg:text-base font-regular ml-4">
                {parse(intl.formatMessage({ id: "Location.LocationAddress" }))}
              </p>
            </div>
            <div className="lg:w-[100%] sm:w-[100%] xs:w-[100%] flex justify-start items-start">
              <img className="mt-[0.2rem]" src={Stopwatch} alt="" />
              <p className="lg:text-[1rem] sm:text-[1rem] xs:text-[0.875rem] lg:text-base font-regular ml-4 lg:w-[500px]">
                {parse(
                  intl.formatMessage({ id: "Location.LocationWorkHours" })
                )}
              </p>
            </div>
            <div className="flex max-h-3 justify-start items-center">
              <img src={Phone} alt="" />
              <a
                href="tel:+78000803003"
                className=" lg:text-[1.3rem] xs:text-[1rem] leading-4 font-medium  ml-4"
              >
                +7 (800) 080 30 03
              </a>
            </div>
            <div className="flex max-h-3 justify-start items-center">
              <img src={Phone} alt="" />
              <a
                href="tel:+77273333003"
                className=" lg:text-[1.3rem] xs:text-[1rem] leading-4 font-medium  ml-4"
              >
                +7 (727) 33 33 003
              </a>
            </div>
            <div className="flex max-h-3 justify-start items-center inline-block">
              <img src={WPhone} alt="" />
              <a
                href="tel:+77763333003"
                className=" lg:text-[1.3rem] xs:text-[1rem] leading-4 font-medium  ml-5"
              >
                +7 (776) 33 33 003
              </a>
            </div>
            <div className="flex max-h-3 justify-start items-center">
              <img src={Message} alt="" />
              <p className="lg:text-[1rem] sm:text-[1rem] xs:text-[0.875rem] lg:text-base font-regular ml-4">
                {parse(intl.formatMessage({ id: "Location.LocationMail" }))}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default injectIntl(Location);

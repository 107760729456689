import * as React from "react";
import { useState } from "react";

import { injectIntl } from "gatsby-plugin-intl";
import ServiceTakeAnalysis from "./ServiceTakeAnalysis";
import GetResults from "./GetResults";
import HouseCall from "./HouseCall";
import GetResultsForm from "../../popups/GetResultsForm";
// @ts-ignore
const ServiceMenu = ({ intl }) => {
  const [showResulGetAnalysisPopup, setShowResultGetAnalysisPopup] =
    useState(false);

  return (
    <div className="grid justify-center pb-12 pt-10">
      <div className="flex xl:max-w-[1200px] xl:w-[1200px] lg:max-w-[1000px] lg:w-[1000px] sm:max-w-[680px] sm:w-[680px] xs:max-w-[300px] xs:w-[300px]  ">
        <div className="grid sm:gap-[2.5rem] xs:gap-[1.5rem]">
          <div className="grid lg:grid-cols-2 xs:grid-cols-1 gap-4 lg:mt-[0rem] sm:mt-[1rem]">
            <ServiceTakeAnalysis />
            <GetResults />
          </div>

          <HouseCall />
        </div>
      </div>
    </div>
  );
};

export default injectIntl(ServiceMenu);

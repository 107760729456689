import * as React from "react";
import { injectIntl } from "gatsby-plugin-intl";
import parse from "html-react-parser";
import { Link } from "gatsby";
import ArrowRight from "../../images/arrow_right.svg";
import NewsItem from "./NewsItem";
// import { newsObjects } from "../../utils/newsObjects";
import { useQuery } from "@apollo/client";
import { GET_NEWS } from "../../query/getNews";
// @ts-ignore
const News = ({ intl }) => {
  const { data } = useQuery(GET_NEWS, {
    variables: {
      locale: intl.locale,
    },
    fetchPolicy: "network-only",
  });

  return data?.blogs?.data?.length !== 0 ? (
    <div className="grid xs:justify-center  sm:pl-0">
      <div className="flex flex-col grid-rows-2 justify-start xl:max-w-[1200px] xl:w-[1200px] lg:max-w-[1000px] lg:w-[1000px] sm:max-w-[680px] sm:w-[680px] xs:max-w-[300px] xs:w-[300px] sm:mb-[5rem] xs:mb-[2.5rem] lg:pl-[0rem] ">
        <div className=" grid items-start h-[2.5rem] grid-cols-2 justify-between mb-4  ">
          <h2 className="lg:text-[2rem] sm:text-[1.75rem] xs:text-[1.25rem] font-semibold leading-5">
            {parse(intl.formatMessage({ id: "NewsTumar.NewsHeader" }))}
          </h2>
          <Link to="/news">
            <div className="flex cursor-pointer justify-end items-start h-[2.5rem] grid-cols-2  sm:-mt-[0.5rem] xs:mt-[0rem]">
              <p className="sm:text-[1.25rem] xs:text-[0.875rem] font-semibold text-[#FF922E]">
                {parse(intl.formatMessage({ id: "NewsTumar.AllNews" }))}
              </p>
              <img
                className="sm:ml-[1rem] xs:ml-[0.5rem] sm:mt-[0.8rem] xs:mt-[0.4rem]"
                src={ArrowRight}
                alt=""
              />
            </div>
          </Link>
        </div>
        <div className=" grid xl:grid-cols-4 sm:grid-cols-1 items-start   gap-9 ">
          {data?.blogs?.data?.slice(0, 3)?.map((news: any) => (
            <Link
              to={`/news-detail/?id=${news.attributes.sharedId}`}
              key={news.id}
            >
              <NewsItem
                intl={intl}
                date={new Date(news.attributes.publishedAt)
                  .toLocaleDateString("en-GB")
                  .replace(/\//g, ".")}
                title={news.attributes.title}
                description={news.attributes.description}
                // img={News2}
                img={news.attributes.image?.data?.attributes?.url}
              />
            </Link>
          ))}
        </div>
      </div>
    </div>
  ) : null;
};

export default injectIntl(News);

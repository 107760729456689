import React, { FC } from "react";
import parse from "html-react-parser";
import { injectIntl } from "gatsby-plugin-intl";

import Lipid from "../../images/lipid.webp";

// @ts-ignore
const ServicePackageItem: FC<ServicePackageItemsProps> = ({
  intl,
  title,
  price,
  img,
}) => (
  <div className="flex flex-col justify-end bg-[#FFF5EB] xl:w-[24rem] xl:h-[24rem] lg:w-[19.5rem]  lg:h-[22rem] sm:w-[21rem] sm:h-[21.75rem] xs:w-[18.75rem] xs:h-auto rounded-[1.25rem] mr-4">
    {" "}
    <h2 className="xs:mt-[2rem] xl:px-[0rem] lg:px-[0.2rem] sm:leading-7 xs:leading-[1.4rem] xs:mt-[1rem] mb-[0.5rem] ml-[2rem] font-medium xl:text-[1.5rem] lg:text-[1.3rem]  sm:text-[1.25rem] xs:text-[1.2rem] ">
      {parse(
        intl.formatMessage({
          id: title,
        })
      )}
    </h2>
    <p className=" xl:px-[0rem] lg:px-[0.2rem] ml-[2rem] sm:font-medium xs:font-regular lg:text-[2rem]  sm:text-[1.75rem] xs:text-[1.4rem] ">
      {price}₸
    </p>
    <div className="xl:h-[17rem] lg:h-[15rem] sm:h-[16rem] xs:h-[12rem]  flex items-end ">
      <img
        src={img ? `https://api.tumar.kz${img}` : Lipid}
        alt=""
        className="xl:mb-[0rem] lg:-mb-[0.25rem] sm:-mb-[0.45rem] xs:-mb-[0.5rem] lg:ml-[8rem] sm:ml-[8rem] xs:ml-[6.7rem] xl:w-[13rem]  lg:w-[12rem]  lg:h-[10.5rem] sm:w-[11rem] sm:h-[10rem] xs:w-[11rem] xs:h-[10rem]  "
      />
    </div>
  </div>
);

export default injectIntl(ServicePackageItem);

import * as React from "react";
import "./AboutUs.css";
import { injectIntl, Link } from "gatsby-plugin-intl";
import Checkbox from "../../images/checkbox.svg";
import DoctorTablet from "../../images/doctorTablet.svg";
import DoctorMobile from "../../images/doctorMobile.svg";
import parse from "html-react-parser";
// @ts-ignore
const AboutUs = ({ intl }) => (
  <div className={`grid justify-center mb-[5rem] about-us-image py-6`}>
    <div className="flex xl:max-w-[1200px] xl:w-[1200px] lg:max-w-[1000px] lg:w-[1000px] sm:max-w-[680px] sm:w-[680px] xs:max-w-[300px] xs:w-[300px] sm:mt-[2rem] xs:mt-[0.5rem]">
      <div className="flex flex-col ">
        <h2 className="font-semibold lg:text-[2rem] sm:text-[1.75rem] xs:text-[1.25rem] leading-5 mb-[1.25rem] ">
          {parse(intl.formatMessage({ id: "AboutUs.AboutUsHeader" }))}
        </h2>
        <p className="font-regular lg:text-[0.9rem] sm:text-[1rem] xs:text-[0.875rem] leading-5 lg:w-[40rem]">
          {parse(intl.formatMessage({ id: "AboutUs.AboutUsDescription" }))}
        </p>
        <div className="flex lg:justify-between sm:flex-row xs:flex-col">
          <div className="mb-[2rem]">
            <div className="lg:flex lg:grid-flow-col  xs:grid xs:grid-cols-2  justify-start mt-[2rem] lg:gap-4 sm:gap-8 xs:gap-4">
              <div className="flex justify-center items-center bg-white  sm:rounded-[1.25rem] xs:rounded-[0.5rem] sm:w-[10.25rem] sm:h-[6.26rem] xs:w-[7.25rem] xs:h-[3rem] ">
                <img
                  src={Checkbox}
                  alt=""
                  className="sm:w-[1.8rem] sm:h-[1.8rem] xs:w-[1rem] xs:h-[1rem] mr-[0.6rem] -mt-[1rem]"
                />
                <div className="flex flex-col">
                  <p className="font-medium sm:text-[1.5rem] xs:text-[1rem]">
                    {parse(
                      intl.formatMessage({
                        id: "AboutUs.Statistics.FoundingYear",
                      })
                    )}
                  </p>
                  <p className="font-medium sm:text-[1rem] xs:text-[0.75rem] leading-5">
                    {parse(
                      intl.formatMessage({ id: "AboutUs.Statistics.Founding" })
                    )}
                  </p>
                </div>
              </div>
              <div className="flex justify-center items-center bg-white  sm:rounded-[1.25rem] xs:rounded-[0.5rem] lg:ml-[0rem] sm:-ml-[2rem] sm:w-[13rem] sm:h-[6.26rem] xs:w-[9rem] xs:h-[3rem]">
                {" "}
                <img
                  src={Checkbox}
                  alt=""
                  className="sm:w-[1.8rem] sm:h-[1.8rem] xs:w-[1rem] xs:h-[1rem] mr-[0.6rem] -mt-[1rem]"
                />
                <div className="flex flex-col">
                  <p className="font-medium sm:text-[1.5rem] xs:text-[1rem]">
                    {parse(
                      intl.formatMessage({
                        id: "AboutUs.Statistics.MoreThan300",
                      })
                    )}
                  </p>
                  <p className="font-medium sm:text-[1rem] xs:text-[0.75rem] leading-5">
                    {parse(
                      intl.formatMessage({ id: "AboutUs.Statistics.Analysis" })
                    )}
                  </p>
                </div>
              </div>
              <div className="flex justify-center items-center bg-white  sm:rounded-[1.25rem] xs:rounded-[0.5rem] sm:w-[11.5rem] sm:h-[6.26rem] xs:w-[8rem] xs:h-[3rem]">
                <img
                  src={Checkbox}
                  alt=""
                  className="sm:w-[1.8rem] sm:h-[1.8rem] xs:w-[1rem] xs:h-[1rem] mr-[0.6rem] -mt-[1rem]"
                />
                <div className="flex flex-col">
                  <p className="font-medium sm:text-[1.5rem] xs:text-[1rem]">
                    1
                  </p>
                  <p className="font-medium sm:text-[1rem] xs:text-[0.75rem] leading-5">
                    {parse(
                      intl.formatMessage({
                        id: "AboutUs.Statistics.Laboratory",
                      })
                    )}
                  </p>
                </div>
              </div>
            </div>
            <Link to="/about-us">
              <button className="mt-[2rem] font-semibold justify-center items-center sm:text-[1.25rem] xs:text-[1rem] border border-2 border-black rounded-[0.5rem] sm:w-[18rem] sm:h-[2.75rem] xs:w-[13rem] xs:h-[2rem] hover:text-white hover:border-none hover:bg-[#001439]">
                {parse(intl.formatMessage({ id: "AboutUs.AboutUsMoreButton" }))}
              </button>
            </Link>
          </div>
          <img
            src={DoctorTablet}
            alt=""
            className="lg:hidden sm:block xs:hidden lg:pt-[0rem] sm:pt-[2rem] xs:pt-[0rem]"
            loading="lazy"
          />

          <img
            src={DoctorMobile}
            alt=""
            className="lg:hidden sm:hidden xs:grid"
            loading="lazy"
          />
        </div>
      </div>
      <div className="flex  ">
        {/*<img*/}
        {/*  src={Doctor}*/}
        {/*  alt=""*/}
        {/*  className="border-none lg:block xl:ml-[12rem] lg:ml-[2rem] xl:mb-[0rem] lg:-mb-[3rem]  xs:hidden  -mt-[7rem] lg:w-[28rem] lg:h-[32rem]"*/}
        {/*/>*/}
        {/*<img*/}
        {/*  src={Girl}*/}
        {/*  alt=""*/}
        {/*  className="lg:block xl:ml-[12rem] lg:ml-[2rem] xl:mb-[0rem] lg:-mb-[3rem]  xs:hidden  -mt-[7rem] lg:w-[28rem] lg:h-[32rem]"*/}
        {/*/>*/}
      </div>
    </div>
  </div>
);
export default injectIntl(AboutUs);

import React, { FC, useRef, useState } from "react";
import parse from "html-react-parser";
import { injectIntl } from "gatsby-plugin-intl";
import { Link } from "gatsby";
import Result from "../../../images/result.webm";
import GetResultsForm from "../../popups/GetResultsForm";

// @ts-ignore
const GetResults: FC<GetResultsProps> = ({ intl }) => {
  const [showGetResultsPopup, setShowGetResultsPopup] = useState(false);

  const videoRef = useRef(null);
  const handleMouseOut = () => {
    // @ts-ignore
    videoRef.current.pause();
  };
  const handleMouseOver = () => {
    // @ts-ignore
    videoRef.current.play();
  };

  return (
    <div className=" flex justify-center items-center bg-[#FFF5EB] xl:w-[37rem] lg:w-[31rem] lg:h-[18.75rem] sm:w-[42rem] sm:h-[18rem] xs:w-[19rem] xs:h-[16.5rem] rounded-[1.25rem]">
      <div className="lg:w-[67%] sm:w-[62%] xs:w-[12rem] lg:ml-[2rem]    ">
        <h2 className="xs:mb-[1.25rem] font-semibold xl:text-[2rem] sm:text-[1.6rem] xs:text-[1.14rem] leading-7">
          {parse(
            intl.formatMessage({
              id: "ServiceMenu.GetResults.GetResultsHeader",
            })
          )}
        </h2>
        <p className="xs:mb-[1.25rem] font-regular lg:text-[1.125rem] sm:text-[1rem] xs:text-[0.8rem] mr-2">
          {parse(
            intl.formatMessage({
              id: "ServiceMenu.GetResults.GetResultsDescription",
            })
          )}
        </p>
        <Link to="">
          <button
            className=" grid bg-[#618EE4] hover:bg-[#617EE4] text-white justify-center items-center lg:w-[16.625rem] sm:w-[12.5rem] sm:h-[2.5rem] xs:w-[9.5rem] xs:h-[2rem] sm:rounded-[0.56rem] xs:rounded-[0.5rem] font-semibold lg:text-[1.3rem] sm:text-[1rem] xs:text-[0.75rem]"
            onClick={() => setShowGetResultsPopup(true)}
          >
            {parse(
              intl.formatMessage({
                id: "ServiceMenu.GetResults.GetResultsButton",
              })
            )}
          </button>
        </Link>
      </div>
      <div className="xl:w-[40%] lg:w-[30%] sm:w-[30%] xs:w-[25%] cursor-pointer mb-10">
        <video
          className="lg:w-[10rem] lg:h-[10rem] sm:w-[13rem] sm:h-[10rem] xs:w-[6rem] xs:h-[6rem]"
          ref={videoRef}
          onMouseOver={handleMouseOver}
          onMouseOut={handleMouseOut}
          muted
        >
          <source
            src="../../../images/result_safari.mov"
            type='video/mov; codecs="hvc1"'
          />
          <source src={Result} type="video/webm;" />
        </video>
      </div>
      <GetResultsForm
        //@ts-ignore
        intl={intl}
        isOpen={showGetResultsPopup}
        onClose={() => setShowGetResultsPopup(false)}
      />
    </div>
  );
};

export default injectIntl(GetResults);

import * as React from "react";
import { injectIntl, Link } from "gatsby-plugin-intl";
import Books from "../../images/books.webm";
import parse from "html-react-parser";
import { useRef } from "react";

// @ts-ignore
const AcademyTumar = ({ intl }) => {
  const videoRef = useRef(null);
  const handleMouseOut = () => {
    // @ts-ignore
    videoRef.current.pause();
  };
  const handleMouseOver = () => {
    // @ts-ignore
    videoRef.current.play();
  };

  return (
    <div className="grid justify-center sm:mb-[5rem] xs:mb-[2rem]">
      <div className="grid sm:grid-cols-2 xs:grid-cols-1 rounded-[1.25rem] xl:max-w-[1200px] xl:w-[1200px] lg:max-w-[1000px] lg:w-[1000px] sm:max-w-[680px] sm:w-[680px] xs:max-w-[300px] xs:w-[300px] bg-[#FFF5EB] p-[2rem] pt-12">
        <div className="grid lg:w-[36rem] sm:w-[26rem] sm:order-1 xs:order-2">
          <h2 className="lg:text-[2rem] sm:text-[1.75rem] xs:text-[1.25rem] font-semibold leading-5 pb-[1.25rem] mt-4">
            {parse(intl.formatMessage({ id: "AcademyTumar.Title" }))}
          </h2>
          <p className="sm:text-[1.125rem] xs:text-[0.75rem]  font-regular leading-6 pb-[1.25rem]">
            {parse(intl.formatMessage({ id: "AcademyTumar.Subtitle" }))}
          </p>
          <Link to="/academy-tumar">
            <button className="grid items-center lg:w-[15rem] sm:w-[14rem] sm:h-[3rem] xs:w-[11rem] xs:h-[2.5rem] rounded-lg sm:text-[1.25rem] xs:text-[1rem] font-semibold bg-[#FF922E] text-white">
              {parse(intl.formatMessage({ id: "AcademyTumar.Button" }))}
            </button>
          </Link>
        </div>
        <div className="grid sm:justify-normal xs:justify-center lg:w-[16rem] sm:w-[13rem] sm:order-2 xs:order-1 h-auto  lg:ml-[7rem] sm:ml-[7rem] cursor-pointer">
          <video
            className=""
            ref={videoRef}
            onMouseOver={handleMouseOver}
            onMouseOut={handleMouseOut}
            muted
          >
            <source
              src="../../images/books_safari.mov"
              type='video/mov; codecs="hvc1"'
            />
            <source src={Books} type="video/webm;" />
          </video>
        </div>
      </div>
    </div>
  );
};

export default injectIntl(AcademyTumar);

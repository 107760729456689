import * as React from "react";
import { injectIntl, Link } from "gatsby-plugin-intl";
import "./MainSlider.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { useQuery } from "@apollo/client";
import { GET_BANNERS } from "../../query/getBanners";
import { cmsImageLoader } from "../../utils/cmsImageLoader";

const MainSlider = ({ intl }) => {
  const { data, loading, error } = useQuery(GET_BANNERS, {
    fetchPolicy: "network-only",
  });

  if (loading) return <div>Loading...</div>;

  if (error) {
    console.error("GraphQL Error:", error);
    return `Error! ${error.message}`;
  }

  const getCorrectImage = (item) => {
    const windowWidth = window.innerWidth;

    if (windowWidth >= 1024) {
      return item.attributes.desktop.data.attributes;
    } else if (windowWidth >= 768 && windowWidth < 1024) {
      return item.attributes.tablet_horizontal.data.attributes;
    } else if (windowWidth >= 480 && windowWidth < 768) {
      return item.attributes.tablet_vertical?.data?.attributes;
    } else {
      return item.attributes.mobile?.data?.attributes;
    }
  };

  return (
    <Carousel
      autoPlay={true}
      showThumbs={false}
      showIndicators={false}
      infiniteLoop={true}
      showStatus={false}
    >
      {data?.banners?.data?.map((item) => (
        <Link key={item.id} to={item.attributes.link}>
          <div>
            <img
              src={cmsImageLoader({
                src: getCorrectImage(item).url,
              })}
              alt={`Image ${item.id + 1}`}
            />
          </div>
        </Link>
      ))}
    </Carousel>
  );
};

export default injectIntl(MainSlider);

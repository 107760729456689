import * as React from "react";
import { injectIntl, Link } from "gatsby-plugin-intl";
import ServicePackageItem from "./ServicePackageItem";
import parse from "html-react-parser";
import ArrowRight from "../../images/arrow_right.svg";

import { servicePackagesObjects } from "../../utils/servicePackagesObjects";
import { useQuery } from "@apollo/client";
import { GET_ANALYSIS_GROUP } from "../../query/getAnalysis";
import { moneyFormatFromNumber } from "../../utils/formatter";

// @ts-ignore
const ServicePackages = ({ intl }) => {
  const { data, loading, error } = useQuery(GET_ANALYSIS_GROUP, {
    variables: {
      locale: intl.locale,
      pagination: {
        pageSize: 3,
      },
    },
  });

  if (loading) return "";

  if (error) return `Error! ${error.message}`;

  return (
    <div className="grid xs:justify-center  sm:pl-0 sm:mt-[5.5rem] xs:mt-[3rem]">
      <div className="flex flex-col sm:grid-rows-2 xs:grid-rows-3 justify-start xl:max-w-[1200px] xl:w-[1200px] lg:max-w-[1000px] lg:w-[1000px] sm:max-w-[680px] sm:w-[680px] xs:max-w-[300px] xs:w-[300px] sm:mb-[5rem] lg:pl-[0rem] xs:mb-[2rem] ">
        <div className="grid items-start h-[2.5rem] sm:grid-cols-2 xs:grid-cols-1 justify-between sm:mb-[2.5rem] xs:mb-[1.125rem]  ">
          <h2 className="lg:text-[2rem] sm:text-[1.4rem] xs:text-[1.25rem] font-semibold leading-5">
            {parse(
              intl.formatMessage({
                id: "ServicePackages.ServicePackagesHeader",
              })
            )}
          </h2>
          <Link to="/complex-service-packages">
            <div className="xs:hidden sm:flex cursor-pointer justify-end items-start h-[2.5rem] grid-cols-2  sm:-mt-[0.5rem] xs:mt-[0rem]">
              <p className="sm:text-[1.25rem] xs:text-[0.875rem] font-semibold text-[#FF922E]">
                {parse(
                  intl.formatMessage({
                    id: "ServicePackages.AllServicePackages",
                  })
                )}
              </p>
              <img
                className="sm:ml-[1rem] xs:ml-[0.5rem] sm:mt-[0.8rem] xs:mt-[0.4rem]"
                src={ArrowRight}
                alt=""
              />
            </div>
          </Link>
        </div>
        <div className="flex flex-no-wrap overflow-visible overflow-x-auto justify-between gap-5">
          {data.analysisGroups.data.map((analysisGroup: any) => (
            <Link
              to={`/complex-service-packages-detail/?id=${analysisGroup.id}`}
            >
              <ServicePackageItem
                key={`service-package-${analysisGroup.id}`}
                intl={intl}
                title={analysisGroup.attributes.title}
                price={moneyFormatFromNumber(analysisGroup.attributes.price)}
                img={analysisGroup.attributes.icon?.data?.attributes?.url}
              />
            </Link>
          ))}
        </div>
        <Link to="/complex-service-packages">
          <div className="sm:hidden xs:grid items-start h-[2.5rem] grid-cols-1 mb-4">
            <div className="sm:hidden xs:flex cursor-pointer items-start mt-[1.125rem]">
              <p className="text-[1rem] font-semibold text-[#FF922E]">
                {parse(
                  intl.formatMessage({
                    id: "ServicePackages.AllServicePackages",
                  })
                )}
              </p>

              <img
                className="ml-[0.5rem] mt-[0.5rem]"
                src={ArrowRight}
                alt=""
              />
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default injectIntl(ServicePackages);

import { gql } from "@apollo/client";

export const GET_BANNERS = gql`
  query getBanners {
    banners(sort: "priority:asc") {
      data {
        id
        attributes {
          desktop {
            data {
              attributes {
                url
              }
            }
          }
          tablet_horizontal {
            data {
              attributes {
                url
              }
            }
          }
          tablet_vertical {
            data {
              attributes {
                url
              }
            }
          }
          mobile {
            data {
              attributes {
                url
              }
            }
          }
          link
        }
      }
    }
  }
`;

import React, { FC, useRef } from "react";
import parse from "html-react-parser";
import { injectIntl, Link } from "gatsby-plugin-intl";
import Car from "../../../images/car.webm";
import "./HouseCall.css";

// @ts-ignore
const HouseCall: FC<HouseCallProps> = ({ intl }) => {
  const videoRef = useRef(null);
  const handleMouseOut = () => {
    // @ts-ignore
    videoRef.current.pause();
  };
  const handleMouseOver = () => {
    // @ts-ignore
    videoRef.current.play();
  };

  return (
    <div className="houseCall flex xs:flex sm:flex-row xs:flex-col sm:items-center sm:justify-around xs:items-center  bg-[#EFF4FC] xl:w-[75rem] lg:w-[62.5rem] lg:h-[20.75rem] sm:w-[42rem] sm:h-[17.6rem] xs:w-[19rem] xs:h-auto rounded-[1.25rem]">
      <div className="lg:w-[50%] sm:w-[38%]  xs:w-[100%] flex xs:justify-center cursor-pointer">
        <video
          className=" mt-[1.5rem] lg:w-[15rem] lg:h-[15rem] sm:w-[16.5rem] sm:h-[13rem] xs:w-[12.5rem] xs:h-[10rem]"
          ref={videoRef}
          onMouseOver={handleMouseOver}
          onMouseOut={handleMouseOut}
          muted
        >
          <source
            src="../../../images/car_safari.mov"
            type='video/mov; codecs="hvc1"'
          />
          <source src={Car} type="video/webm;" />
        </video>
      </div>
      <div className="flex flex-col items-start sm:w-[45%] xs:w-[90%]">
        <h2 className="xs:mb-[1.25rem] font-semibold lg:text-[2rem] sm:text-[1.6rem] xs:text-[1.25rem] leading-5 mt-4">
          {parse(
            intl.formatMessage({
              id: "ServiceMenu.HouseCall.HouseCallHeader",
            })
          )}
        </h2>
        <p className="sm:w-[80%] xs:mb-[1.25rem] font-regular lg:text-[1.125rem] sm:text-[1rem] xs:text-[0.875rem]">
          {parse(
            intl.formatMessage({
              id: "ServiceMenu.HouseCall.HouseCallDescription",
            })
          )}
        </p>
        <Link to="/house-call">
          <button className="sm:mb-[0rem] xs:mb-[2.5rem] grid border border-2 border-black  justify-center items-center lg:w-[16.625rem] sm:w-[12.5rem] sm:h-[2.5rem] xs:w-[8.5rem] xs:h-[2rem] sm:rounded-[0.56rem] xs:rounded-[0.5rem] font-semibold lg:text-[1.3rem] sm:text-[1rem] xs:text-[0.75rem] hover:text-white hover:border-none hover:bg-[#001439]">
            {parse(
              intl.formatMessage({
                id: "ServiceMenu.HouseCall.HouseCallButton",
              })
            )}
          </button>
        </Link>
      </div>
    </div>
  );
};

export default injectIntl(HouseCall);

import React, { useEffect } from "react";
import "./index.css";

import ServiceMenu from "../components/index/ServiceMenu/ServiceMenu";
import ServicePackages from "../components/index/ServicePackages";
import AboutUs from "../components/index/AboutUs";
import Location from "../components/index/Location/Location";
import Layout from "../components/Layout/Layout";
import AcademyTumar from "../components/index/AcamedyTumar";
import News from "../components/index/News";
import MainSlider from "../components/index/MainSlider";

const IndexPage = () => {
  useEffect(() => {
    document.title = "Tumar, медицинская лаборатория";
  }, []);

  return (
    <Layout>
      <>
        {/*<Slider />*/}
        <MainSlider />

        <ServiceMenu />

        {/*<ServiceInfo />*/}

        <ServicePackages />
        <AboutUs />
        <News />
        <AcademyTumar />
        <Location />
      </>
    </Layout>
  );
};

export default IndexPage;
